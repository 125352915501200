
.dropdown-menu>.dropdown-item {
    text-wrap: wrap;
    white-space: pre-wrap;
}


.dropdown-title {
    max-width: calc(100% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown-toggle.btn,
.dropdown-toggle.btn:hover,
.dropdown-toggle.btn[aria-expanded="true"],
.dropdown-toggle.btn:not(:disabled):not(:disabled):active {
    background-color: white;
    color: var(--new-color, #6C757D);
    border: 1px solid #CED4DA;
    text-align: left;
    overflow: hidden;
}

.dropdown-toggle.btn:disabled {
    background-color: #E9ECEF;
    color: #6C757D;
    border: 1px solid #CED4DA
}

.dropdown-toggle.btn[aria-expanded="true"],
.dropdown-toggle.btn:not(:disabled):not(:disabled):active {
    border: 1px solid #86b7fe !important;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.dropdown-toggle.btn::after {
    position: absolute;
    margin-left: 0;
    right: 15px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.inline {
    display: flex;
    vertical-align: top;
    align-items: flex-start;
    column-gap: 20px;
    flex-wrap: wrap;
}

table.spreadsheet-table {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    margin-bottom: 50px;
    font-size: 14px;
}

table.spreadsheet-table>thead>tr>th {
    padding: 10px;
    background-color: #E9ECEF;
    border-left: 1px solid #6C757D;
    border-right: 1px solid #6C757D;
}

table.spreadsheet-table>tbody>tr>td {
    overflow-wrap: break-word;
    background-color: white;
    border-bottom: 1px solid #6C757D;
    border-left: 1px solid #6C757D;
    border-right: 1px solid #6C757D;
    padding: 10px;
    vertical-align: top;
    min-width: 77px;
}

@media only screen and (max-width: 960px) {
    table.spreadsheet-table {
        font-size: 12px;
        table-layout: fixed;
    }
  }

table.spreadsheet-table>tbody>tr>td.endCell {
    border-right: none;
    border-left: none;
}

table.spreadsheet-table>thead>tr>th.endCell {
    border-right: none;
    border-left: none;
}

.nav-title {
    display: inline;
    margin: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    max-height: 43px;
}

div.nav, body .nav-container>.navbar, .nav-container-database>.navbar{
    font-style: normal;
    font-family: Arial;
    background-color: #E7F1FF !important;
    width: 100%;
    height: 43px;
    position: fixed;
    z-index: 1;
    padding-left: 20px;
}

.database-nav{
    background-color: #CFE2FF;
    padding-left: 100px !important;
    position: fixed;
    width: 100%;
    top:43px;
    font-style: normal;
    font-family: Arial;
    z-index: 1;
    padding-left: 20px;
    transition: height 500ms ease-in-out;
    height: 0;
    overflow: hidden
}
body .shadow{
    box-shadow: 1
}
  
.sub-link-container{
    padding-top: 10px;
}
a.nav, .database-link{
    text-decoration: none;
    color: #0D6EFD;
    display: inline;
    padding: 9px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    cursor: pointer;
    border-bottom: 2px solid transparent;
}

.sign-out {
    text-decoration: none;
    color: #0D6EFD;
    display: inline;
    padding: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    cursor: pointer;
}

a.active, .database-link-active {
    border-bottom: 2px solid #495057;
    color: #495057;
}

.nav-container{
    padding-bottom: 100px;
    background-color: #F8F9FA;
}
.nav-container-database{
    padding-bottom: 142px;
    background-color: #F8F9FA;
}
.page-input{
    max-width: 43px;
    line-height: 21px !important;
}
.page-input::-webkit-outer-spin-button,
.page-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.page>.dropdown-toggle.btn,
.page>.dropdown-toggle.btn:hover,
.page>.dropdown-menu {
    min-width: 150px;
    max-width: 150px;
    line-height: 21px;
}


.page-text {
    display: flex;
    justify-content: center;
    min-height: 34px;
    align-items: center;
}

.page-nav {
    max-height: 34px;
    justify-content: center;
    gap: 10px !important;
}

.page-input[type=number] {
    appearance: none;
    -moz-appearance: textfield;
}

.time-icon{
    z-index: 5;
    cursor: pointer;
    float: right;
    margin-top: 5px;
}

.active{
    color: #0D6EFD
}


/* Copyright notice at bottom of page */

.copyright {
    text-align: center;
    font-size: 13px;
    line-height: 20px;
    color: #6C757D;
    border-top: 1px solid #DEE2E6;
    margin-bottom: 4px;
    background-color: #F8F9FA;
}