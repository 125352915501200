
body .alert{
    margin-top: 20px;
    --bs-alert-margin-bottom: 0px
}

.medium>.dropdown-toggle.btn,
.medium>.dropdown-toggle.btn:hover,
.medium>.dropdown-menu {
    min-width: 150px;
    max-width: 150px;
}

.selected>.dropdown-toggle.btn,
.selected>.dropdown-toggle.btn:hover,
.selected>.dropdown-menu {
    color: #212529
}

.dropdown-menu>.dropdown-item {
    text-wrap: wrap;
    white-space: pre-wrap;
}

.dropdown-title {
    max-width: calc(100% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown-toggle.btn,
.dropdown-toggle.btn:hover,
.dropdown-toggle.btn[aria-expanded="true"],
.dropdown-toggle.btn:not(:disabled):not(:disabled):active {
    background-color: white;
    color: var(--new-color, #6C757D);
    border: 1px solid #CED4DA;
    text-align: left;
    overflow: hidden;
}

.dropdown-toggle.btn:disabled {
    background-color: #E9ECEF;
    color: #6C757D;
    border: 1px solid #CED4DA
}

.dropdown-toggle.btn[aria-expanded="true"],
.dropdown-toggle.btn:not(:disabled):not(:disabled):active {
    border: 1px solid #86b7fe !important;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.dropdown-toggle.btn::after {
    position: absolute;
    margin-left: 0;
    right: 15px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

button.compare-button {
    width: 120px;
}
.button-link {
    background-color: transparent;
    border-color: "transparent";
    color: #0D6EFD;
    min-height: 38px;
    max-height: 38px;
    padding: 4px 8px 4px 8px;
    cursor: pointer
}

.button-link.danger {
    color: #FF0000;
}

.button-link.deactivated {
    color: #6C757D;
    cursor: default;
}

.inline {
    display: flex;
    vertical-align: top;
    align-items: flex-start;
    column-gap: 20px;
    flex-wrap: wrap;
}

.inline-modal{
    display: flex;
    vertical-align: top;
    align-items: flex-start;
    column-gap: 10px;
    flex-wrap: wrap;
}

.flex-right-modal{
    margin-left: auto;
    display: flex;
    vertical-align: top;
    align-items: flex-start;
    column-gap: 10px;
    order: 2;
}

.flex-right {
    margin-left: auto;
    display: flex;
    vertical-align: top;
    align-items: flex-start;
    column-gap: 20px;
    order: 2;
}

.filters-section {
    border-bottom: 1px solid #6C757D;
    width: 100%;
    background-color: #E9ECEF;
    padding: 10px;
}

body.modal-open {
    padding-right: 0px !important;
}
.multiview-modal {
    min-width: 90%;
}
.multiview-1{
    width: 50%;
    font-size: 10px;
}
body .edit-modal {
    min-width: 60%;
    max-width: 60%;
    width: 60%;
}
table.multiview-table{
    border-spacing: 10px;
    border-collapse: separate;
}
table.multiview-table>tbody>tr>td{
    width: 50%;
    padding: 10px;
    border: 1px solid black;   
    vertical-align: top;
}
.modal-content>.modal-body{
    padding: 20px;
}
.modal-content>.modal-header{

    border-bottom: transparent;
}.modal-content>.modal-footer{
    border-top: transparent;
    background-color: #DEE2E6;
}
.modal-title-text {
    line-height: 27px;
    text-align: left;
    font-size: 40px;
    font-weight: 500;
    padding-bottom: 10px;
}
.margin {
    margin-bottom: 20px;
    margin-top: 20px;
}

.margin-bottom {
    margin-bottom: 20px;
}
.margin-top {
    margin-top: 20px;
}
.save-notif{
    font-size: 12px;
    color: gray;
    display: inline-block;
    line-height: 38px;
}
.save-error{
    font-size: 12px;
    line-height: 38px;
    padding: 0px 10px 0px 10px !important;
    margin-top: 0px !important;
}