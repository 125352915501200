.pageContainer{
    min-height: calc(100vh - 21px);
    background-color: #F8F9FA;
    padding-bottom: 50px;
}
.signin-container{
    min-height: max(calc(100vh - 21px),  585px);
    background-color: #F8F9FA;
    /* padding-bottom: 50px; */
}
.new-prompt-container{
    margin-bottom: 100px;
    width: 53%;
    margin-left:5%
}
.form-box-left {
    width: 64%;
    display: inline-block;
    margin-bottom: 50px;
}
.app-container{
    min-height: 100vh;
    max-height: 100vh;
    background-color: #F8F9FA;
}
.component-container { 
    max-width: 90%;
    margin-left: 5%
}