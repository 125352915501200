/* Sign in page css */
.signInPage {
    background-color: #F8F9FA;
    padding-bottom: 50px;
}

.nav-container-signin>.navbar{
    font-style: normal;
    font-family: Arial;
    background-color: #E7F1FF !important;
    width: 100%;
    height: 43px;
    position: fixed;
    z-index: 1;
    padding-left: 20px;
}

.signinBox {
    transform: translate(0, 50%);
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%);
    height: 400px;
}

h1.signin {
    text-align: center;
}

button.signin {
    background-color: #0D6EFD;
    color: white;
    width: 288px;
    border-radius: 4px;
    cursor: pointer;
}