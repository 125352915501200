.pageContainer{
    min-height: calc(100vh - 21px);
    background-color: #F8F9FA;
    padding-bottom: 50px;
}
.form-box-left {
    width: 64%;
    display: inline-block;
    margin-bottom: 50px;
}
.component-container { 
    max-width: 90%;
    margin-left: 5%
}
.spinner-container{
    text-align: center;
}