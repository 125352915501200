body .alert {
    margin-top: 20px;
    --bs-alert-margin-bottom: 0px
}

.button-run {
    float: right;
    width: 160px;
    min-height: 38px;
    max-height: 38px;
    margin-left: 20px;
}

.inline {
    display: flex;
    vertical-align: top;
    align-items: flex-start;
    column-gap: 20px;
    flex-wrap: wrap;
}

.inline-small-gap {
    display: flex;
    vertical-align: top;
    align-items: flex-start;
    column-gap: 5px;
    flex-wrap: wrap;
}

.form-section {
    width: 100%;
    display: inline-block;
    background-color: #E9ECEF;
    height: auto;
    padding: 30px 30px 30px 30px;
}

.run {
    padding: 11px 11px 11px 0px;
    background-color: #DEE2E6;
    height: 60px;
}

.margin {
    margin-bottom: 20px;
    margin-top: 20px;
}

.margin-bottom {
    margin-bottom: 20px;
}

.margin-top {
    margin-top: 20px;
}

.text-input {
    flex-grow: 1;
}

.text-input-container {
    margin-top: auto;
    margin-bottom: auto;
    display: inline-flex;
}