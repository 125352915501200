
.compare-border-bottom{    
    border-bottom: 1px solid #ADB5BD;
}
body .alert{
    margin-top: 20px;
    --bs-alert-margin-bottom: 0px
}

.medium>.dropdown-toggle.btn,
.medium>.dropdown-toggle.btn:hover,
.medium>.dropdown-menu {
    min-width: 150px;
    max-width: 150px;
}

.selected>.dropdown-toggle.btn,
.selected>.dropdown-toggle.btn:hover,
.selected>.dropdown-menu {
    color: #212529
}

.large>.dropdown-toggle.btn,
.large>.dropdown-toggle.btn:hover,
.large>.dropdown-menu {
    min-width: 250px;
    max-width: 250px;
    width: 250px;
}

.dropdown-menu>.dropdown-item {
    text-wrap: wrap;
    white-space: pre-wrap;
}


.dropdown-title {
    max-width: calc(100% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown-toggle.btn,
.dropdown-toggle.btn:hover,
.dropdown-toggle.btn[aria-expanded="true"],
.dropdown-toggle.btn:not(:disabled):not(:disabled):active {
    background-color: white;
    color: var(--new-color, #6C757D);
    border: 1px solid #CED4DA;
    text-align: left;
    overflow: hidden;
}

.dropdown-toggle.btn:disabled {
    background-color: #E9ECEF;
    color: #6C757D;
    border: 1px solid #CED4DA
}

.dropdown-toggle.btn[aria-expanded="true"],
.dropdown-toggle.btn:not(:disabled):not(:disabled):active {
    border: 1px solid #86b7fe !important;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.dropdown-toggle.btn::after {
    position: absolute;
    margin-left: 0;
    right: 15px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.blue {
    color: #0A58CA;
    font-weight: 600;
}

.button-run {
    float: right;
    width: 160px;
    min-height: 38px;
    max-height: 38px;
    margin-left: 20px;
}

.compare-keywords-total {
    width: 100%;
    border-bottom: 1px solid #6C757D;
    padding: 10px;
}

.compare-keywords-unique {
    width: 100%;
    padding: 10px;
}

.directions {
    width: 30%;
    display: inline-block;
    vertical-align: top;
    padding: 20px 0px 0px 25px;
}

.inline {
    display: flex;
    vertical-align: top;
    align-items: flex-start;
    column-gap: 20px;
    flex-wrap: wrap;
}
.inline-modal{
    display: flex;
    vertical-align: top;
    align-items: flex-start;
    column-gap: 10px;
    flex-wrap: wrap;
}

.flex-right {
    margin-left: auto;
    display: flex;
    vertical-align: top;
    align-items: flex-start;
    column-gap: 20px;
    order: 2;
}

.icon {
    color: #0D6EFD;
    line-height: 37px;
    cursor: pointer;
}

.icon-parameter {
    margin: auto;
    line-height: 37px;
    cursor: pointer;
}

.form-box-left {
    width: 64%;
    display: inline-block;
    margin-bottom: 50px;
}

.form-section {
    width: 100%;
    display: inline-block;
    background-color: #E9ECEF;
    height: auto;
    padding: 30px 30px 30px 30px;
}

li {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}

body.modal-open {
    padding-right: 0px !important;
}

.edit-modal {
    min-width: 720px;
}
.compare-modal {
    min-width: 1000px;
    /* background-color: #E9ECEF; */
}
.modal-content>.modal-body{
    padding: 20px;
}
.modal-content>.modal-header{

    border-bottom: transparent;
}.modal-content>.modal-footer{
    border-top: transparent;
    background-color: #DEE2E6;
}
.modal-title-text {
    line-height: 27px;
    text-align: left;
    font-size: 40px;
    font-weight: 500;
    padding-bottom: 10px;
}
.run {
    padding: 11px 11px 11px 0px;
    background-color: #DEE2E6;
    height: 60px;
}

.margin {
    margin-bottom: 20px;
    margin-top: 20px;
}

.margin-bottom {
    margin-bottom: 20px;
}
.margin-top {
    margin-top: 20px;
}

.summary-section {
    width: 100%;
    display: flex;
    background-color: #E9ECEF;
    /* height: fit-content; */
    padding: 30px 30px 30px 30px;
    margin-bottom: 50px;
    /* overflow-y: ; */
}

.summary-right {
    min-width: 290px;
    max-width: 290px;
    font-size: 13px;
    line-height: 20px;
    /* height: 265px; */

}

.summary-right-top {
    font-size: 16px;
    line-height: 20px;
}

.summary-left {
    width: 70%;
    min-height: 265px;
    margin-left: 20px;
}

.summary-prompt-box {
    /* margin-top: 20px; */
    background-color: white;
    min-height: 150px;
    padding: 20px;

}

table.compare-summary-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 15px 0px;
}
table.compare-summary-table>tbody>tr>td{
    border-bottom: 1px solid #6C757D;
    padding: 10px 0px 10px 0px;
    vertical-align: top;
    width: 48%;
}

table.compare-summary-table>tbody>tr>td.top{
    padding: 0px 0px 10px 0px;
}
table.compare-summary-table>tbody>tr>td.bottom{
    border-bottom: 0px ;
    padding: 10px 0px 0px 0px;
}

table.compare-table {
    width: 100%;
    margin-bottom: 50px;
}

table.compare-table>thead>tr>th {
    padding: 10px;
    background-color: #E9ECEF;
    border-left: 1px solid #6C757D;
    border-right: 1px solid #6C757D;
}

table.compare-table>tbody>tr>td {
    min-width: 100px;
    background-color: white;
    border-bottom: 1px solid #6C757D;
    border-left: 1px solid #6C757D;
    border-right: 1px solid #6C757D;
    padding: 15px;
    vertical-align: top;
}

table.compare-table>tbody>tr>td.endCell {
    border-right: none;
    border-left: none;
}

table.compare-table>thead>tr>th.endCell {
    border-right: none;
    border-left: none;
}

table.compare-table>tbody>tr>td.compare {
    border-bottom: 1px solid #6C757D;
    border-left: 1px solid #6C757D;
    border-right: 1px solid #6C757D;
    padding: 0px;
}


.visual-section {
    /* position: absolute; */
    width: 100%;
    /* background-color: #E9ECEF; */
    /* height: 360px; */
    /* padding: 30px 30px 30px 30px; */
}
.markup{
    color: yellow
}

.visual-prompt-container {
    display: flex;
    gap: 10px;
    margin-top: 10px;

}

.visual-prompt-label {
    width: 100%;
}

.visual-prompt-box {
    background-color: white;
    width: 100%;
    padding: 10px;
    display: inline-block;
    border: 1px solid black;
    margin-top: 10px;
    /* height: 120px; */
    overflow-y: auto;
    margin-bottom: 5px;
}